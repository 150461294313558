import React from 'react';
import Navigation from 'components/Navigation';
import Header from 'components/Header';
import About from 'components/About';
import Locations from 'components/Locations';
import Signup from 'components/Signup';
import Contact from 'components/Contact';
import Footer from 'components/Footer';

const App = () => (
  <>
    <div className="App">
      <Navigation />
      <Header />
      <About />
      <Locations />
      <Signup />
      <Contact />
      <Footer />
    </div>
  </>
);

export default App;
