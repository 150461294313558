import React from 'react';
import './Header.scss';

const Header = () => (
  <header className="masthead">
    <div className="container d-flex h-100 align-items-center">
      <div className="mx-auto text-center">
        <h1 className="mx-auto my-0 text-uppercase">NaturePass</h1>
        <h2 className="text-white-50 mx-auto mt-2 mb-5">
          Her kommer din portal for jakt og fiske i hele Norge
        </h2>
        <a href="#about" className="btn btn-primary js-scroll-trigger">
          Kom igang
        </a>
      </div>
    </div>
  </header>
);

export default Header;
