import {TOGGLE_MENU} from './actionTypes';

const initialState = {
  menuOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MENU:
      return {...state, menuOpen: !state.menuOpen};

    default:
      return state;
  }
};
