import React, {Component} from 'react';
import './Navigation.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {Navbar, Nav, Container} from 'react-bootstrap';
// eslint-disable-next-line no-unused-vars
import $ from 'jquery';

// eslint-disable-next-line react/prefer-stateless-function
class Navigation extends Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    // eslint-disable-next-line no-console
    console.log($('#mainNav').offset().top);
    if ($('#mainNav').offset().top > 100) {
      $('#mainNav').addClass('navbar-shrink');
    } else {
      $('#mainNav').removeClass('navbar-shrink');
    }
  };

  render() {
    return (
      <Navbar bg="light" expand="lg" id="mainNav" className="fixed-top">
        <Container>
          <Navbar.Brand href="#home">NaturePass</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            Menu
            <FontAwesomeIcon icon={faBars} />
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarResponsive">
            <Nav className="ml-auto" as="ul">
              <Nav.Item as="li">
                <Nav.Link href="#about">About</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link href="#locations">Locations</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link href="#contact">Contact</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Navigation;
