import React from 'react';
import ipadImage from 'img/ipad.png';
import './About.scss';

const About = () => (
  <section id="about" className="about-section text-center">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <h2 className="text-white mb-4">
            Bygget av fangstfolk, for fangstfolk
          </h2>
          <p className="text-white-50">
            NaturePass er et prøveprosjekt for en portal tilegnet jakt, fiske og
            andre friluftsaktivteter. Portalen inneholder foreløpig bare
            testlokasjoner og tilbud, og kan ikke brukes til reel bestilling av
            kort.
          </p>
        </div>
      </div>
      <img src={ipadImage} className="img-fluid" alt="" />
    </div>
  </section>
);

export default About;
